<template>
  <div class="t4CategoriesMenu">
    <t4HorizontalSec />
    <div class="categories-holder">
      <div class="container">
        <div class="columns is-multiline">
          <!-- <router-link
            :to="getUrl(category)"
            class="column is-3 has-text-centered"
            v-for="category in categories"
            :key="category.id"
          >
            <figure class="image is-5by4">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${category.image}`"
                :alt="category.title"
              />
            </figure>
            <span>{{ category.title }}</span>
          </router-link> -->

          <router-link
            v-if="!foodItem.food_items"
            :to="`/menu/${foodItem.menu_section_id}/fooditems/${foodItem.id}`"
            class="column is-3 has-text-centered"
            v-for="foodItem of items"
            :key="foodItem.id"
          >
            <figure class="image is-5by4">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${foodItem.image}`"
                alt
              />
            </figure>
            <span>{{ foodItem.name }}</span>
          </router-link>

          <div
            v-if="foodItem.food_items"
            @click="filterByCategory(foodItem)"
            class="column is-3 has-text-centered"
            v-for="foodItem of items"
            :key="foodItem.id"
          >
            <figure class="image is-5by4">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${foodItem.image}`"
                alt
              />
            </figure>
            <span>{{ foodItem.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import t4HorizontalSec from "../../layouts/theme-four/horizontalSection";
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";

export default {
  name: "ThemeFourCategoriesComponent",
  components: {
    t4HorizontalSec,
    VLazyImage,
  },
  computed: mapState(["menu", "theme_four_selected_section"]),
  watch: {
    menu(menu) {
      this.categories = menu;
    },
    theme_four_selected_section(nval, oval) {
      this.filterByCategory(nval);
    },
  },
  // components: {
  //   VLazyImage,
  // },
  data() {
    return {
      categories: [],
      items: [],
    };
  },
  methods: {
    filterByCategory(category) {
      if (!category.id) return;
      this.$store.commit("theme_four_selected_section", category);

      if (category.sub_section && category.sub_section.length) {
        this.items = category.sub_section;
      } else {
        this.items = category.food_items;
      }
    },
    getUrl(category) {
      if (!category.sub_section.length) return `menu/${category.id}/food_items`;

      return `submenu/${category.id}`;
    },
  },
  mounted() {
    this.categories = this.menu;

    let arr = [];

    // for (let category of this.categories) {
    //   Array.prototype.push.apply(arr, category.food_items);
    // }
    this.items = this.categories;
    this.filterByCategory(this.theme_four_selected_section);
  },
};
</script>

<style lang='scss' scoped>
.t4CategoriesMenu {
  .categories-holder {
    padding: 1rem;

    & .columns {
      & .column {
        border: 1px solid #fff;
        position: relative;

        & figure {
          margin-bottom: 12%;
        }

        & span {
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 18px;
          font-weight: 500;
          color: white;
          width: 100%;
          text-align: center;
          background-color: rgba(20, 6, 2, 0.6);
          padding: 3px 0;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
