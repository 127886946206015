<template>
  <div class="callWaiterParent">
    <div class="container" v-if="restaurant_detail.theme.id !== 4">
      <btnsNav />
    </div>
    <t4topnav v-if="restaurant_detail.theme.id === 4" />
    <callForm />
    <appNav
      :route_back="{
        name: 'MenuParentHomeComponent',
        params: {},
        display_name: restaurant_detail.localization.misc_home,
      }"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import callForm from "@/components/waiter/Form";
import appNav from "@/components/layouts/AppNav";
import btnsNav from "@/components/ui/BtnsNav";
import t4topnav from "@/components/layouts/theme-four/topNav";

export default {
  components: {
    callForm,
    appNav,
    btnsNav,
    t4topnav,
  },
  computed: mapState(["restaurant_detail"]),
  data() {
    return {
      logo: "",
    };
  },
  methods: {
    setHeaderDetail() {
      this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
    },
  },
  mounted() {
    this.setHeaderDetail();
  },
};
</script>

<style lang='scss' scoped>
.callWaiterParent {
  .large-logo-detail {
    width: 150px;
    height: 150px;
  }

  height: 100vh;
}
</style>