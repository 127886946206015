<template>
  <div class="categories-holder">
    <t4HorizontalSec
      v-if="restaurant_detail.theme.id === 4"
      style="margin-bottom: 1.5rem"
    />

    <div class="container">
      <div class="columns is-multiline">
        <router-link
          :to="getUrl(category)"
          class="column is-3 has-text-centered"
          v-for="category in categories"
          :key="category.id"
        >
          <figure class="image is-5by4">
            <v-lazy-image
              :src="`${$store.getters.base_url}/${category.image}`"
              :alt="category.title"
            />
          </figure>
          <span>{{ category.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";
import t4HorizontalSec from "../../components/layouts/theme-four/horizontalSection";

export default {
  name: "MainMenuCategoriesComponent",
  computed: mapState(["menu", "restaurant_detail"]),
  watch: {
    menu(menu) {
      this.categories = menu;
      console.log(this.categories);
    },
  },
  components: {
    VLazyImage,
    t4HorizontalSec,
  },
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    getUrl(category) {
      if (!category.sub_section.length) return `menu/${category.id}/food_items`;

      return `submenu/${category.id}`;
    },
  },
  mounted() {
    this.categories = this.menu;
  },
};
</script>

<style lang='scss' scoped>
.categories-holder {
  padding: 1rem;

  & .columns {
    & .column {
      border: 1px solid #fff;
      position: relative;

      & figure {
        margin-bottom: 12%;
      }

      & span {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 18px;
        font-weight: 500;
        color: white;
        width: 100%;
        text-align: center;
        background-color: rgba(20, 6, 2, 0.6);
        padding: 3px 0;
        text-transform: uppercase;
      }
    }
  }
}
</style>
