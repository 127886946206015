const util = {
    likenDislike(store, item_id) {
        let liked_items = store.getters.fav_items;
        let item_index = liked_items.indexOf(item_id);
        if (item_index === -1)
            liked_items.push(item_id);
        else
            liked_items.splice(item_index, 1);


        store.commit('fav_items', liked_items);
        return item_index === -1;
    }


}
export default util;