import { render, staticRenderFns } from "./FoodItemDetail.vue?vue&type=template&id=31fe5729&scoped=true&"
import script from "./FoodItemDetail.vue?vue&type=script&lang=js&"
export * from "./FoodItemDetail.vue?vue&type=script&lang=js&"
import style0 from "./FoodItemDetail.vue?vue&type=style&index=0&id=31fe5729&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fe5729",
  null
  
)

export default component.exports