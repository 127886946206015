<template>
  <div>
    <detailHeader heading="Menu" v-if="restaurant_detail.theme.id != 4" />
    <t4Topnav v-if="restaurant_detail.theme.id === 4" />
    <!-- <div class="desc-holder">
                <p>{{description}}</p>
    </div>-->

    <menuCategories
      v-if="
        restaurant_detail.theme_setting.menu_view != 'list'
      "
    />
    <!-- <theme4menuCategories
      v-if="
        restaurant_detail.theme_setting.menu_view != 'list' && restaurant_detail.theme.id === 4"/> -->
    <listView
      v-if="
        restaurant_detail.theme_setting.menu_view == 'list'
      "
    />
    
    <mainFooter />
    <bottomNav
      :route_back="{
        name: 'MenuParentHomeComponent',
        params: {},
        display_name: restaurant_detail.localization.misc_home,
      }"
    />
  </div>
</template>

<script>
import detailHeader from "@/components/layouts/DetailHeader";
import t4Topnav from "@/components/layouts/theme-four/topNav";
import menuCategories from "@/components/menu/Categories";
import theme4menuCategories from "@/components/menu/theme-four/Categories";
import bottomNav from "@/components/layouts/AppNav";
import mainFooter from "@/components/layouts/Footer";

import listView from "@/components/menu/listview/List";

import { mapState } from "vuex";

export default {
  name: "menuParentView",
  components: {
    detailHeader,
    t4Topnav,
    menuCategories,
    theme4menuCategories,
    listView,
    bottomNav,
    mainFooter,
  },
  computed: mapState(["restaurant_detail"]),

  watch: {
    restaurant_detail(data) {
      this.description = data.description;
    },
  },

  data() {
    return {
      description: "",
      theme_id: 1,
    };
  },
  mounted() {
    this.description = this.restaurant_detail.description;
    this.$store.commit("theme_four_selected_section", {});
  },
};
</script>
<style scoped>
.desc-holder {
  padding: 1rem;
}

.desc-holder p {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
</style>
