<template>
  <div id="app">
    <sweet-modal ref="cart" title="Your Order">
      <div
        class="cart-item"
        v-for="(cart_item, index) in cart.food_list"
        :key="index"
      >
        <p class="item-cost">
          {{ restaurant_detail.currency.prefix }}
          {{ cart_item.price * cart_item.quantity
          }}{{ restaurant_detail.currency.suffix }}
        </p>
        <span class="count-number">
          <button
            class="btn left dec"
            @click="manipulateItem(cart_item.uuid, 0)"
          >
            -
          </button>
          <input
            type="text"
            readonly="readonly"
            class="count-number-input"
            :value="cart_item.quantity"
          />
          <button
            class="btn right inc"
            @click="manipulateItem(cart_item.uuid, 1)"
          >
            +
          </button>
        </span>
        <span class="item-name">{{ cart_item.name }}</span>
      </div>
      <div class="cart-summary">
        <span>Payable Amount:</span>
        <span>
          <small>{{ restaurant_detail.currency.prefix }}</small>
          {{ cart.cart_amount.payable }}
          <small>{{ restaurant_detail.currency.suffix }}</small>
        </span>
      </div>
      <div class="columns is-mobile">
        <div class="column"></div>
        <div class="column">
          <button class="button is-success" @click="closeCart">Checkout</button>
        </div>
        <div class="column"></div>
      </div>
    </sweet-modal>

    <button class="button cart-float-icon" v-if="show_cart" @click="openCart">
      <span class="counter">{{ cart.food_list.length }}</span>
      <figure class="image is-32x32">
        <img :src="`${$store.getters.base_url}/img/icons/shopping-cart.svg`" />
      </figure>
    </button>
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
  name: "EntryPoint",
  props: ["hash"],
  components: {
    SweetModal,
    SweetModalTab,
  },

  computed: mapState(["restaurant_detail", "locale", "cart"]),
  watch: {
    $route(newval) {
      this.setCartShow(newval);
      setTimeout(() => {
        this.setBackground();
      }, 200);
      if (newval.name == "MenuParentHomeComponent")
        this.$store.commit("theme_four_selected_section", {});
    },
    restaurant_detail(data) {
      this.setBackground();
    },
    locale(data) {
      this.getRestaurantCatalogue();
    },
  },

  data() {
    return {
      // hash: "YW6UGCJCfAmNY9TB",
      backgroundImg:
        "http://parlour.eazyfnb.com/images/restaurant/logos/1578376619.jpg",
      show_cart: false,
    };
  },
  methods: {
    openCart() {
      this.$refs.cart.open();
    },
    closeCart() {
      this.$refs.cart.close();
      if (this.cart.food_list.length) {
        this.$router.push({ name: "checkoutPage" });
      }
    },
    manipulateItem: function (uuid, flag) {
      if (flag) this.$store.commit(`incrementItem`, uuid);
      else this.$store.commit(`decrementItem`, uuid);
      this.$emit("updateCart", this.cart);
    },

    setCartShow(route) {
      if (!this.restaurant_detail.cart_feature) {
        this.show_cart = false;
        return false;
      }

      if (
        route.name == "MainReviewFormComponent" ||
        route.name == "checkoutPage" ||
        route.name == "MainCallToWaiterFormComponent" ||
        route.name == "MenuParentHomeComponent" ||
        route.name == "MenuParentComponent"
      ) {
        this.show_cart = false;
        return false;
      }
      this.show_cart = true;
      return true;
    },
    getRestaurantCatalogue() {
      let self = this;
      console.log(self.locale);
      axios
        .post(`${this.$store.getters.base_url}/api/subdomain_catalogue`, {
          embed_key: self.hash,
          lang: self.locale,
        })
        .then((res) => {

          if (res.data.error) {
            self.$store.commit("setRestaurantDetail", {
              id: 0,
              name: "",
              logo: "",
              description: "",
              background_image: "",
              menu_feature: true,
              waiter_feature: true,
              feedback_feature: true,
              cart_feature: false,
              onlinelink: "",
              facebook: "",
              insta: "",
              snapchat: "",
              languages: [{ id: 0, code: "en", name: "English" }],
              theme: {
                id: 1,
              },
            });

            self.$store.commit("setMenu", [
              {
                id: 0,
                title: "",
                count: 0,
                image: "",
                sub_section: [],
                food_items: [],
              },
            ]);

            return;
          }

          self.$store.commit(
            "setRestaurantDetail",
            res.data.data.restaurant_detail
          );

          if (res.data.data.restaurant_detail.menu_feature) self.getMenu();
        });
    },
    getMenu() {
      let self = this;
      axios
        .post(`${this.$store.getters.base_url}/api/subdomain_menu`, {
          embed_key: self.hash,
          lang: self.locale,
        })
        .then((res) => {
      
          self.$store.commit("setMenu", res.data.data);
        });
    },
    setBackground() {
      console.log(this.$route.name);
      if (
        this.restaurant_detail.theme.id === 3 &&
        (this.$route.name == "MenuParentHomeComponent" ||
          this.$route.name == "MenuParentComponent")
      ) {
        document.body.style.backgroundColor = "#ccc";
        document.querySelector(
          ".elevated-div"
        ).style.backgroundImage = `linear-gradient(180deg,transparent,rgba(0,0,0,.83)),url(${this.$store.getters.base_url}/${this.restaurant_detail.background_image})`;
        document.documentElement.style.setProperty(
          "--main-primary",
          `${this.restaurant_detail.theme_setting.primary_color}`
        );
        document.documentElement.style.setProperty(
          "--main-dark",
          `${this.restaurant_detail.theme_setting.dark_color}`
        );
        document.documentElement.style.setProperty(
          "--main-light",
          `${this.restaurant_detail.theme_setting.light_color}`
        );
      } else {
        document.body.style.backgroundImage = `linear-gradient(180deg,transparent,rgba(0,0,0,.83)),url(${this.$store.getters.base_url}/${this.restaurant_detail.background_image})`;
      }
    },
    detectLanguage() {
      if (!this.$store.getters.locale) {
        let lang = this.restaurant_detail.languages.filter(
          (language) => language.code == navigator.language
        );

        if (lang.length) {
          this.$store.commit("setCurrentLang", lang);
        } else {
          this.$store.commit("setCurrentLang", "en");
        }
      }
    },
    setTableNo() {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let table_no = url.searchParams.get("table_no");
      this.$store.commit("table_no", table_no);
      if (table_no)
        this.$store.commit(
          "setSubdomainUrl",
          `${location.origin}/?table_no=${table_no}#/`
        );
      else this.$store.commit("setSubdomainUrl", `${location.origin}/#/`);

      // console.log(`${location.origin}/?table_no=${table_no}#/`);
      // console.log(`${location.origin}/#/`);
    },
  },
  mounted() {
    let self = this;
    self.getRestaurantCatalogue();
    self.setBackground();
    self.detectLanguage();

    this.setCartShow(this.$route);
    this.setTableNo();
  },
};
</script>
<style lang='scss' scoped>
.cart-float-icon {
  position: fixed;
  bottom: 60px;
  right: 3%;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;

  transform: translateZ(0);
  will-change: transform;

  backface-visibility: hidden;
  z-index: 3;
  height: 50px;
  width: 50px;
  overflow: visible;

  & .counter {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 28px;
    width: 28px;
    background: #e94a35;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50%;
    text-indent: 0;
    transition: transform 0.2s 0.5s;
  }

  & figure {
    display: flex;
    align-items: center;
  }
}

.cart-item {
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #777;

  .item-cost {
    order: 3;
    flex: 0.2;
    color: #1a1a1a;
    font-size: 16px;
  }

  p,
  span {
    margin-right: 15px;
  }

  input {
    width: 30px;
    height: 30px;
  }

  .btn {
    background: orange;
    color: #fff;
    border: 0;
    padding: 5px 10px;
    font-size: 16px;
  }

  .count-number {
    order: 2;
  }

  .item-name {
    order: 1;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
  }
}

.cart-summary {
  display: flex;
  flex-direction: row;
  padding: 10px;

  & span {
    color: #1a1a1a;
    font-weight: 600;
    font-size: 22px;

    &:first-child {
      flex: 1;
      text-align: right;
      font-weight: 500;
      margin-right: 10px;
    }
  }
}
</style>
