function calculatePrice(state) {
    let total_payable = 0;
    state.cart.cart_amount.payable = 0
    for (let i = 0; i < state.cart.food_list.length; i++) {
        total_payable += state.cart.food_list[i].total_price;
        // total_payable += (state.cart.food_list[i].price * state.cart.food_list[i].quantity);

    }
    state.cart.cart_amount.payable = total_payable;

}

export default {

    theme_four_selected_section: (state, data) => {
        
        state.theme_four_selected_section = data
    },
    setMenu: (state, data) => {
        state.menu = data
    },
    setRestaurantDetail: (state, data) => {
        state.restaurant_detail = data
    },
    setSelectedFoodItem: (state, data) => {
        state.restaurant_detail = data
    },
    setSubdomainUrl: (state, data) => {
        state.subdomain_url = data
    },
    recommended: (state, data) => {
        state.recommended = data
    },
    locale: (state, data) => {
        state.locale = data
    },
    add_to_cart(state, data) {
        let uuid = data.item.uuid ? data.item.uuid : data.item.id;
        state.cart.restaurant_id = data.item.restaurant_id;

        let flag = -1;


        for (let i = 0; i < state.cart.food_list.length; i++) {

            if (state.cart.food_list[i].uuid == uuid) {
                flag = i;
                break;
            }
        }
        if (flag != -1) {

            state.cart.food_list[flag].quantity += 1;
            state.cart.food_list[flag].total_price = state.cart.food_list[flag].quantity * state.cart.food_list[flag].price;

        } else {

            data.item.total_price = data.item.price;
            if (data.item.min_item) {
                data.item.total_price = data.item.total_price * data.item.min_item;
                data.item.quantity = data.item.quantity * data.item.min_item;
            }

            state.cart.food_list.push(data.item);


        }

        calculatePrice(state);


    },
    incrementItem(state, uuid) {


        let flag = -1;

        for (let i = 0; i < state.cart.food_list.length; i++) {

            if (state.cart.food_list[i].uuid == uuid) {
                flag = i;
                break;
            }
        }

        if (flag != -1) {
            state.cart.food_list[flag].quantity += 1;
            state.cart.food_list[flag].total_price = state.cart.food_list[flag].quantity * state.cart.food_list[flag].price;


        } else {
            return;
        }
        // console.log(calculatePrice(state));
        calculatePrice(state);

    },
    decrementItem(state, uuid) {
        let flag = -1;

        for (let i = 0; i < state.cart.food_list.length; i++) {

            if (state.cart.food_list[i].uuid == uuid) {
                flag = i;
                break;
            }
        }

        if (flag != -1) {
            // for min item
            if (state.cart.food_list[flag].quantity <= state.cart.food_list[flag].min_item) {
                state.cart.food_list.splice(flag, 1);
                calculatePrice(state);

                return;
            }


            state.cart.food_list[flag].quantity -= 1;
            state.cart.food_list[flag].total_price = state.cart.food_list[flag].quantity * state.cart.food_list[flag].price;

        } else {
            return;
        }


        if (!state.cart.food_list[flag].quantity)
            state.cart.food_list.splice(flag, 1);

        calculatePrice(state);

    },
    resetCart(state) {
        state.cart = {
            active: false,
            restaurant_id: 0,
            restaurant_detail: {
                name: '',
                address: '',
                image: '',
            },
            restaurant_slug: '--',
            food_list: [],
            cart_amount: {
                payable: 0
            },
        };
    },
    fav_items: (state, data) => {
        state.fav_items = data
    },

    table_no: (state, data) => {
        state.table_no = data
    },

};
