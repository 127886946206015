<template>
  <div>
    <!--        <button class="button cart-float-icon" @click="openCart" v-if="restaurant_detail.cart_feature">-->
    <!--            <span>{{cart.food_list.length}}</span>-->
    <!--            <span class="icon is-small">-->
    <!--        <img :src="`${$store.getters.base_url}/img/icons/shopping-cart.svg`"/>-->
    <!--      </span>-->
    <!--        </button>-->

    <itemDetail/>
    <recommended/>
    <appNav :route_back="route_back"/>

    <sweet-modal ref="cart" title="Your Order">
      <div class="cart-item" v-for="(cart_item, index) in cart.food_list" :key="index">
        <p class="item-cost">
          {{restaurant_detail.currency.prefix}}
          {{cart_item.price*cart_item.quantity}}{{restaurant_detail.currency.suffix}}
        </p>
        <span class="count-number">
          <button class="btn left dec" @click="manipulateItem(cart_item.uuid,0)">-</button>
          <input
              type="text"
              readonly="readonly"
              class="count-number-input"
              :value="cart_item.quantity"
          />
          <button class="btn right inc" @click="manipulateItem(cart_item.uuid,1)">+</button>
        </span>
        <span class="item-name">{{cart_item.name}}</span>
      </div>
      <div class="cart-summary">
        <span>Payable Amount:</span>
        <span>
          <small>{{restaurant_detail.currency.prefix}}</small>
          {{cart.cart_amount.payable}}
          <small>{{restaurant_detail.currency.suffix}}</small>
        </span>
      </div>
      <div class="columns is-mobile">
        <div class="column"></div>
        <div class="column">
          <router-link class="button is-success" to="/checkout">Checkout</router-link>
        </div>
        <div class="column"></div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
    import {SweetModal, SweetModalTab} from "sweet-modal-vue";

    import itemDetail from "@/components/menu/FoodItemDetail.vue";
    import recommended from "@/components/menu/Recommended";
    import appNav from "@/components/layouts/AppNav.vue";
    import {mapGetters} from "vuex";

    export default {
        computed: mapGetters(["cart", "restaurant_detail"]),

        components: {
            SweetModal,
            SweetModalTab,
            itemDetail,
            recommended,
            appNav
        },
        data() {
            return {
                route_back: {
                    name: "",
                    params: {},
                    display_name: ""
                }
            };
        },
        methods: {
            openCart() {
                this.$refs.cart.open();
            },
            manipulateItem: function (uuid, flag) {
                if (flag) this.$store.commit(`incrementItem`, uuid);
                else this.$store.commit(`decrementItem`, uuid);
                this.$emit("updateCart", this.cart);
            }
        },
        mounted() {
            this.route_back.name = "MainFoodItemsComponentfood";
            this.route_back.params = {
                sub_section_id: this.$route.params.sub_section_id
            };
            this.route_back.display_name = this.restaurant_detail.localization.misc_back;

        }
    };
</script>

<style lang="scss" scoped>
  .cart-float-icon {
    border-radius: 50px;
    border: 1px solid red;
    color: #fff;
    background: red;
    position: absolute;
    bottom: 10%;
    right: 2%;
    z-index: 100;

    &:hover {
      border: 1px solid red;
    }

    & img {
      width: 20px;
      height: 20px;
    }
  }

  .cart-item {
    display: flex;
    flex-direction: row;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #777;

    .item-cost {
      order: 3;
      flex: 0.2;
      color: #1a1a1a;
      font-size: 16px;
    }

    p,
    span {
      margin-right: 15px;
    }

    input {
      width: 30px;
      height: 100%;
    }

    .btn {
      background: orange;
      color: #fff;
      border: 0;
      padding: 5px 10px;
      font-size: 16px;
    }

    .count-number {
      order: 2;
    }

    .item-name {
      order: 1;
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #1a1a1a;
    }
  }

  .cart-summary {
    display: flex;
    flex-direction: row;
    padding: 10px;

    & span {
      color: #1a1a1a;
      font-weight: 600;
      font-size: 22px;

      &:first-child {
        flex: 1;
        text-align: right;
        font-weight: 500;
        margin-right: 10px;
      }
    }
  }
</style>
