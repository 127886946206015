<template>
  <div class="content">
   
    <div v-if="restaurant_detail.theme.id === 1">
      <mainHeader />
      <mainNav />
    </div>

    <div v-if="restaurant_detail.theme.id === 2">
      <theme2Header />
      <theme2Nav />
    </div>

    <div v-if="restaurant_detail.theme.id === 3">
      <theme3Wrapper />
    </div>
    <div v-if="restaurant_detail.theme.id === 4">
      <themefourheader />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import mainHeader from "./layouts/Header";
import mainNav from "./layouts/Nav";

// theme2 components
import theme2Header from "./layouts/theme-two/Header";
import theme2Nav from "./layouts/theme-two/Nav";

// theme3 components
import theme3Wrapper from "./layouts/theme-three/Wrapper";

// theme4 components
import themefourheader from "./layouts/theme-four/Header";

export default {
  name: "MenuParentComponent",
  computed: mapState(["restaurant_detail"]),
  watch: {
    restaurant_detail(data) {},
  },
  data() {
    return {};
  },

  components: {
    mainHeader,
    mainNav,

    theme2Nav,
    theme2Header,

    theme3Wrapper,

    themefourheader,
  },
  mounted() {
    let self = this;
    console.log(this.restaurant_detail.theme.id);
  },
};
</script>

<style lang='scss' scoped>
</style>

