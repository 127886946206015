<template>
  <div class="container">
    <div class="columns is-centered is-mobile is-marginless">
      <div class="column" :class="{ mql: mql, 'is-half': !mql }">
        <div class="custom-dropdown">
          <input type="checkbox" id="menu" />
          <label for="menu" id="nav-icon"
            >&#9776; &nbsp;
            <span>{{
              restaurant_detail.localization.misc_go_to_your_favourite
            }}</span></label
          >

          <div class="multi-level">
            <div class="item" v-for="section in menu" :key="section.id">
              <input type="checkbox" :id="`section-${section.id}`" />
              <img
                :src="`${$store.getters.base_url}/embed/images/arrow.svg`"
                v-if="section.sub_section.length"
                class="arrow"
              />

              <label :for="`section-${section.id}`">
                <span @click="getUrl(section)">{{ section.title }}</span>
              </label>

              <ul v-if="section.sub_section.length">
                <li
                  v-for="sub_section in section.sub_section"
                  :key="sub_section.id"
                >
                  <div class="sub-item">
                    <input
                      type="checkbox"
                      :for="`sub_section-${sub_section.id}`"
                    />
                    <label :for="`sub_section-${sub_section.id}`">
                      <span @click="goToSubSection(sub_section)">{{
                        sub_section.title
                      }}</span>
                    </label>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "dropdownNav",
  data() {
    return {
      mql: true,
    };
  },
  computed: mapState(["menu", "restaurant_detail"]),

  methods: {
    getUrl(category) {
      if (!category.sub_section.length) {
        this.$router.push({
          name: "section_food_items",
          params: { category_id: category.id },
        });
        // location.reload();
      }
      return `#`;
    },
    goToSubSection(category) {
      this.$router.push({
        name: "MainFoodItemsComponentfood",
        params: { sub_section_id: category.id },
      });
      // location.reload();
    },
  },
  mounted() {
    this.mql = window.matchMedia("(max-width: 600px)").matches;
  },
};
</script>

<style scoped>
.multi-level,
.item ul,
.custom-dropdown input[type="checkbox"] {
  display: none;
}

#menu:checked ~ .multi-level,
.item input:checked ~ ul {
  display: block;
}

/*Arrow*/

.arrow {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  float: left;
  z-index: 0;
  margin: 17px 1em 0 2em;
}

.item input + .arrow {
  transform: rotate(-90deg);
  transition: 0.1s;
}

.item input:checked + .arrow {
  transform: rotate(0deg);
  transition: 0.1s;
}

/*Styles*/

.custom-dropdown label:hover {
  cursor: pointer;
}

.custom-dropdown label {
  width: 100%;
  display: block;
  z-index: 3;
  position: relative;
}

.custom-dropdown {
  width: 100%;
  background-color: white;
  overflow-x: hidden;
}

.custom-dropdown label a,
.custom-dropdown .sub-item label a {
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
}

#nav-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 50px;
  padding-left: 1em;
  color: black;
  background-color: #fff;
  font-weight: 400;
}

#nav-icon span {
  font-size: 16px;
  color: black;
}

.custom-dropdown ul,
.custom-dropdown li,
label {
  line-height: 50px;
  margin: 0;
  padding: 0 2em;
  list-style: none;
  text-decoration: none;
  color: #000;
  font-weight: 100;
  width: 100%;
}

.item ul {
  padding: 0 0.25em;
}

.custom-dropdown li a {
  line-height: 50px;
  margin: 0;
  padding: 0 4em;
  list-style: none;
  text-decoration: none;
  color: #000;
  font-weight: 100;
}
</style>