import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import home from "./views/Home.vue";
import menu from "./views/Menu.vue";
import subMenu from "./views/SubMenu.vue";
import foodItem from "./views/FoodItem.vue";
import SectionFoodItem from "./views/SectionFoodItem.vue";
import callToWaiter from "./views/CallWaiter";
import feedBack from "./views/Feedback";
import foodItemList from "./views/FoodItemList";
import SectionFoodItemList from "./views/SectionFoodItemList";
import checkout from "./views/Checkout";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/checkout",
      name: "checkoutPage",
      component: checkout
    },
    {
      path: "/restaurant/feedback/",
      name: "MainReviewFormComponent",
      component: feedBack
    },
    {
      path: "/submenu/:sub_section_id/fooditems",
      name: "MainFoodItemsComponentfood",
      component: foodItemList
    },
    {
      path: "/restaurant/callforwaiter/",
      name: "MainCallToWaiterFormComponent",
      component: callToWaiter
    },
    {
      path: "/submenu/:sub_section_id/fooditems/:item_id",
      name: "sub_section_food_item",
      component: foodItem
    },
    {
      path: "/menu/:category_id/fooditems/:item_id",
      name: "section_food_item",
      component: SectionFoodItem
    },

    {
      path: "/submenu/:category_id",
      name: "MainSubMenuComponent",
      component: subMenu
    },
    {
      path: "/menu/:category_id/food_items",
      name: "section_food_items",
      component: SectionFoodItemList
    },
    {
      path: "/menu",
      name: "MainMenuCategoriesComponent",
      component: menu
    },
    {
      path: "/home",
      name: "MenuParentHomeComponent",
      component: home
    },
    {
      path: "/*",
      name: "MenuParentComponent",
      component: home
    }
  ],
  mode: "hash",
  scrollBehavior() {
    // location.reload();
    document.getElementById("app").scrollIntoView();
  }
});
