<template>
  <div class="main-parent">
    <menuParent />
    <mainFooter />
  </div>
</template>

<script>
import menuParent from "@/components/Home.vue";
import mainFooter from "@/components/layouts/Footer.vue";
export default {
  components: {
    menuParent,
    mainFooter,
  },
};
</script>
<style scoped>
html.has-navbar-fixed-bottom {
  padding-bottom: 0;
}
.main-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
</style>
