<template>
  <div>
    <t4topnav v-if="restaurant_detail.theme.id === 4" />

    <mainHeader v-if="restaurant_detail.theme.id != 4" />
    <reviewForm />
    <mainFooter />
    <appNav
      :route_back="{
        name: 'MenuParentHomeComponent',
        params: {},
        display_name: restaurant_detail.localization.misc_home,
      }"
    />
  </div>
</template>

<script>
import mainHeader from "@/components/layouts/Header";
import reviewForm from "@/components/review/Form";
import mainFooter from "@/components/layouts/Footer";
import appNav from "@/components/layouts/AppNav";
import { mapState } from "vuex";
import t4topnav from "@/components/layouts/theme-four/topNav";

export default {
  computed: mapState(["restaurant_detail"]),

  components: {
    mainHeader,
    reviewForm,
    mainFooter,
    appNav,
    t4topnav,
  },
};
</script>
