<template>
  <div id="item_wrapper">
    <t4topnav v-if="restaurant_detail.theme.id === 4" />
    <t4HorizontalSec v-if="restaurant_detail.theme.id === 4" />

    <div class="item-detail">
      <dropDownNav v-if="restaurant_detail.theme.id !== 4" />
      <div class="container">
        <btnsNav v-if="restaurant_detail.theme.id !== 4" />
        <div class="columns is-multiline">
          <div class="column is-12-mobile is-6-tablet is-6-desktop">
            <figure class="image is-5by4">
              <v-lazy-image
                :src="`${$store.getters.base_url}/${food_item.image}`"
                :alt="food_item.name"
              />
            </figure>

            <div class="buttons">
              <button
                class="button"
                @click="addFoodItem(food_item)"
                v-if="restaurant_detail.cart_feature"
              >
                {{ restaurant_detail.localization.menu_add_to_cart }}
              </button>

              <button
                class="button heart-btn heart-clicked"
                v-if="fav_items.indexOf(food_item.id) != -1"
                @click="likenDislike()"
              >
                <span style="color: white">{{ likes }}</span>

                <span class="icon is-small">
                  <img
                    :src="`${$store.getters.base_url}/img/icons/heart-solid.svg`"
                  />
                </span>
              </button>

              <button
                class="button heart-btn"
                v-if="fav_items.indexOf(food_item.id) == -1"
                @click="likenDislike()"
              >
                <span style="color: blue">{{ likes }}</span>

                <span class="icon is-small">
                  <img
                    :src="`${$store.getters.base_url}/img/icons/heart-solid.svg`"
                  />
                </span>
              </button>

              <button class="button share-btn" @click="shareModal">
                <span class="icon is-small">
                  <img
                    :src="`${$store.getters.base_url}/img/icons/share-alt-solid.svg`"
                  />
                </span>
              </button>
            </div>
          </div>

          <div class="column is-12-mobile is-6-tablet is-6-desktop">
            <div class="table-container">
              <table class="table is-fullwidth">
                <tbody>
                  <tr>
                    <th>
                      <span class="table-heading">{{
                        restaurant_detail.localization.menu_item_name
                      }}</span>
                    </th>

                    <td>
                      <span class="table-text">{{ food_item.name }}</span>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <span class="table-heading">{{
                        restaurant_detail.localization.menu_item_description
                      }}</span>
                    </th>

                    <td>
                      <span class="table-text desc">
                        {{ food_item.description }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <span class="table-heading">{{
                        restaurant_detail.localization.menu_price
                      }}</span>
                    </th>

                    <td>
                      <span class="table-text">
                        <small>{{ restaurant_detail.currency.prefix }}</small>
                        {{ food_item.price }}
                        <small>{{ restaurant_detail.currency.suffix }}</small>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <div class="field is-grouped">
                      <div
                        class="control"
                        v-for="food_attribute in food_item.food_attributes"
                        :key="food_attribute.id"
                      >
                        <div class="tags has-addons">
                          <span class="tag is-dark">{{
                            food_attribute.name
                          }}</span>
                          <span class="tag is-danger">{{
                            food_attribute.detail
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <sweet-modal ref="variationModal" title="Select Variation">
        <p class="is-size-5 has-text-black-bis">{{ selectedFoodItem.name }}</p>
        <p>
          Price:
          {{ restaurant_detail.currency.prefix }}
          <span>{{ this.selectedFoodItem.calculated_price }}</span>
          {{ restaurant_detail.currency.suffix }}
        </p>
        <p style="margin-bottom: 20px">{{ selectedFoodItem.description }}</p>
        <div v-for="(variation, var_index) in selectedFoodItem.variations">
          <div class="notification">
            <p class="is-size-5 has-text-black">{{ variation.title }}</p>
            <span
              style="display: none; color: red"
              :id="`var-error-${variation.id}`"
              >Please Select Option</span
            >
          </div>
          <div class="columns is-mobile">
            <div
              class="column"
              v-for="(option, opt_index) in variation.options"
            >
              <div v-if="!checkBoxType(var_index)">
                <input
                  type="radio"
                  :id="`opt${option.id}`"
                  @click="optionSelected(var_index, opt_index, $event, true)"
                />

                <label class="custom-control-label" :for="`opt${option.id}`">
                  {{ option.name }}
                  <span v-if="option.price != 0">
                    (
                    <small>{{ restaurant_detail.currency.prefix }}</small>
                    +{{ option.price }}
                    <small>{{ restaurant_detail.currency.suffix }}</small
                    >)
                  </span>
                </label>
              </div>

              <div
                class="custom-control custom-radio"
                v-if="checkBoxType(var_index)"
              >
                <input
                  type="checkbox"
                  :id="`opt${option.id}`"
                  :name="`var${var_index}`"
                  @click="optionSelected(var_index, opt_index, $event)"
                />
                <label class="custom-control-label" :for="`opt${option.id}`">
                  {{ option.name }}
                  <span v-if="option.price != 0">
                    (
                    <small>{{ restaurant_detail.currency.prefix }}</small>
                    +{{ option.price }}
                    <small>{{ restaurant_detail.currency.suffix }}</small>
                    )
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column"></div>
          <div class="column" @click="addCustomizedFoodItem">
            <button class="button is-danger">
              {{ restaurant_detail.localization.menu_add_to_cart }}
            </button>
          </div>
          <div class="column"></div>
        </div>
      </sweet-modal>

      <sweet-modal ref="shareModal" title="Share on social media">
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-dark">f</span>
              <span class="tag is-info">
                <ShareNetwork
                  network="facebook"
                  :url="current_url"
                  title="Checkout This Item"
                  description="Checkout This Food Item"
                  :quote="`${food_item.name} ,${food_item.description} `"
                  style="color: #fff; font-weight: bold"
                  >Share on Facebook</ShareNetwork
                >
              </span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-dark">t</span>
              <span class="tag is-info">
                <ShareNetwork
                  network="twitter"
                  :url="current_url"
                  :title="`${food_item.name},${food_item.description} `"
                  style="color: #fff; font-weight: bold"
                  >Share on Twitter</ShareNetwork
                >
              </span>
            </div>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
import { mapState } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import util from "../../assets/js/util";
import axios from "axios";
import dropDownNav from "../layouts/DropDownNav";
import btnsNav from "@/components/ui/BtnsNav";
import t4topnav from "@/components/layouts/theme-four/topNav";
import t4HorizontalSec from "../../components/layouts/theme-four/horizontalSection";

export default {
  name: "MainSectionFoodItemDetailComponent",

  components: {
    VLazyImage,
    SweetModal,
    SweetModalTab,
    dropDownNav,
    btnsNav,
    t4topnav,
    t4HorizontalSec,
  },
  computed: mapState([
    "menu",
    "restaurant_detail",
    "fav_items",
    "subdomain_url",
  ]),

  watch: {
    menu(dat) {
      this.getSectionFoodItem();
    },
  },
  data() {
    return {
      likes: "",
      share_data: {
        title: "",
        text: "",
        url: "",
      },
      current_url: location.href,
      section_id: 0,
      item_id: 0,
      food_item: {
        id: 0,
        name: "",
        price: 0,
        description: "",
        image: "",
        customize: 0,
        restaurant_id: 0,
        food_type: "",
        menu_section_id: 0,
        menu_sub_section_id: 0,
        food_attributes: [],
        calculated_price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
        //  cart
      },
      selectedFoodItem: {
        calculated_price: 0,
        customize: false,
        description: "",
        id: 0,
        image: "",
        name: "",
        price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
      },
    };
  },
  methods: {
    likenDislike() {
      let liked = util.likenDislike(this.$store, this.food_item.id);

      let self = this;
      axios
        .post(`${this.$store.getters.base_url}/api/like_n_dislike`, {
          item_id: self.food_item.id,
          like: liked,
        })
        .then((res) => {
          self.likes = res.data.likes;
        });
    },

    getSectionFoodItem() {
      if (!this.menu) return;
      this.section_id = this.$route.params.category_id;
      this.item_id = this.$route.params.item_id;

      let food_items = [];
      for (let i = this.menu.length - 1; i >= 0; i--) {
        if (this.menu[i].id == this.section_id) {
          food_items = this.menu[i].food_items;
          break;
        }
      }

      for (let i = food_items.length - 1; i >= 0; i--) {
        if (food_items[i].id == this.item_id) {
          this.food_item = food_items[i];
          break;
        }
      }

      this.getRecommendedItems(food_items);
    },
    getRecommendedItems(foodItems_) {
    let  foodItems = foodItems_.slice();

      for (
        var j, x, i = foodItems.length;
        i;
        j = Math.floor(Math.random() * i),
          x = foodItems[--i],
          foodItems[i] = foodItems[j],
          foodItems[j] = x
      );
      let limit = 6;
      let recommended = [];
      for (let i = 0; i < foodItems.length && i < 5; i++) {
        if (foodItems[i].id != this.food_item.id) {
          recommended.push({
            name: foodItems[i].name,
            image: `${this.$store.getters.base_url}/${foodItems[i].image}`,
            route: `${this.subdomain_url}menu/${this.section_id}/fooditems/${foodItems[i].id}`,
          });
        }
      }
      this.$store.commit("recommended", recommended);
    },

    //  cart methods

    checkBoxType(var_index) {
      let max = parseInt(this.selectedFoodItem.variations[var_index].max);
      if (!max) return true;

      return max > 1;
    },
    generateUid() {
      this.selectedFoodItem.calculated_price = this.selectedFoodItem.price;
      this.uid = this.selectedFoodItem.id + ",";

      for (let variation of this.selectedFoodItem.variations) {
        for (let option of variation.options) {
          if (option.checked) {
            this.uid += String(option.id);
            this.selectedFoodItem.calculated_price += parseInt(option.price);
          }
        }
      }
    },

    optionSelected(var_index, opt_index, e, radio = false) {
      if (radio) {
        for (
          let i = 0;
          i < this.selectedFoodItem.variations[var_index].options.length;
          i++
        ) {
          this.selectedFoodItem.variations[var_index].options[
            i
          ].checked = false;
        }
      }

      this.selectedFoodItem.variations[var_index].options[opt_index].checked =
        e.target.checked;

      this.generateUid();

      if (!radio) this.validateMax(var_index, opt_index);
    },
    validateMax(var_index, opt_index) {
      let min = this.selectedFoodItem.variations[var_index].min;
      let max = this.selectedFoodItem.variations[var_index].max;

      let checked_opt = this.selectedFoodItem.variations[
        var_index
      ].options.filter((opt) => {
        document.getElementById(`opt${opt.id}`).disabled = false;
        return opt.checked === true;
      }).length;
      let disable_remaining = checked_opt >= max;

      for (
        let i = 0;
        i < this.selectedFoodItem.variations[var_index].options.length;
        i++
      ) {
        if (disable_remaining)
          document.getElementById(
            `opt${this.selectedFoodItem.variations[var_index].options[i].id}`
          ).disabled = !this.selectedFoodItem.variations[var_index].options[i]
            .checked;
      }
    },

    mapSelectedFoodItem(food_item) {
      let flag = true;
      this.selectedFoodItem.calculated_price = food_item.price;
      this.selectedFoodItem.customize = food_item.customize;
      this.selectedFoodItem.description = food_item.description;
      this.selectedFoodItem.id = food_item.id;
      this.selectedFoodItem.image = food_item.image;
      this.selectedFoodItem.price = food_item.price;
      this.selectedFoodItem.name = food_item.name;
      for (let variation of food_item.variations) {
        let temp_var = {
          id: variation.id,
          max: variation.max,
          min: variation.min,
          options: [],
          title: variation.title,
          type: variation.type,
        };

        for (let option of variation.options) {
          temp_var.options.push({
            id: option.id,
            name: option.name,
            price: option.price,
            enabled: true,
            checked: false,
          });
        }
        if (flag) this.selectedFoodItem.variations[0] = temp_var;
        else this.selectedFoodItem.variations.push(temp_var);
        flag = false;
      }
    },
    validateCustomizeFooditem() {
      let flag = true;
      for (let variation of this.selectedFoodItem.variations) {
        let checked = 0;
        for (let option of variation.options) {
          if (option.checked) checked++;
        }
        if (variation.type == "MAND") {
          if (!checked) {
            flag = false;
            document.getElementById(`var-error-${variation.id}`).style.display =
              "block";
          } else {
            document.getElementById(`var-error-${variation.id}`).style.display =
              "none";
          }
        }
      }

      return flag;
    },
    addCustomizedFoodItem() {
      if (!this.validateCustomizeFooditem()) return;

      let item = {
        id: this.selectedFoodItem.id,
        uuid: this.uid,
        name: this.selectedFoodItem.name,
        min_item: this.selectedFoodItem.min_item,
        price: this.selectedFoodItem.calculated_price,
        quantity: 1,
        restaurant_id: this.food_item.restaurant_id,
        variations: this.selectedFoodItem.variations,
      };

      this.$store.commit("add_to_cart", {
        item: item,
      });
      this.$refs.variationModal.close();

      this.selectedFoodItem = {
        calculated_price: 0,
        customize: false,
        description: "",
        id: 0,
        image: "",
        name: "",
        price: 0,
        variations: [
          {
            id: 0,
            max: 0,
            min: 0,
            options: [
              {
                enabled: true,
                checked: false,
                id: 0,
                name: "",
                price: 0,
              },
            ],
            title: "",
            type: "",
          },
        ],
      };

      this.calculated_price = 0;
      this.uid = "";
      this.$vToastify.success({
        position: "top-right",

        title: "Item Added",

        body: "Your food item is added to cart",

        type: "success",

        canTimeout: true,

        defaultTitle: true,

        duration: 1500,
      });
    },
    addFoodItem(food_item) {
      if (food_item.customize) {
        this.selectedFoodItem = {
          calculated_price: 0,
          customize: false,
          description: "",
          id: 0,
          image: "",
          name: "",
          price: 0,
          variations: [
            {
              id: 0,
              max: 0,
              min: 0,
              options: [
                {
                  enabled: true,
                  checked: false,
                  id: 0,
                  name: "",
                  price: 0,
                },
              ],
              title: "",
              type: "",
            },
          ],
        };

        this.mapSelectedFoodItem(food_item);

        this.calculated_price = this.selectedFoodItem.price;

        this.$refs.variationModal.open();
      } else {
        let item = {
          id: food_item.id,
          uuid: food_item.id,
          name: food_item.name,
          price: food_item.price,
          min_item: food_item.min_item,
          quantity: 1,
          restaurant_id: food_item.restaurant_id,
        };

        this.$store.commit("add_to_cart", {
          item: item,
        });

        this.$vToastify.success({
          position: "top-right",

          title: "Item Added",

          body: "Your food item is added to cart",

          type: "success",

          canTimeout: true,

          defaultTitle: true,

          duration: 1500,
        });
      }
    },

    shareModal() {
      let self = this;

      this.share_data.text = `${this.food_item.name} : ${this.food_item.description}`;
      this.share_data.url = location.href;
      try {
        fetch(`${this.$store.getters.base_url}/${this.food_item.image}`)
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            let file = new File([blob], "picture.jpg", { type: "image/jpeg" });
            let filesArray = [file];

            try {
              if (navigator.canShare({ files: filesArray })) {
                self.share_data.files = filesArray;
              }
              navigator.share(self.share_data);
            } catch (e) {
              self.$refs.shareModal.open();
            }
          });
      } catch (e) {
        // self.$refs.shareModal.open();
      }
    },
    getLikes() {
      let self = this;
      axios
        .post(`${this.$store.getters.base_url}/api/get_likes`, {
          item_id: self.food_item.id,
        })
        .then((res) => {
          self.likes = res.data.likes;
        });
    },
  },
  mounted() {
    this.getSectionFoodItem();
    this.getLikes();

    var element = document.getElementById("item_wrapper");
    var top = element.offsetTop;
    window.scrollTo(0, top);
  },
};
</script>

<style lang="scss" scoped>
.item-detail {
  padding: 1rem;
  position: relative;

  figure {
    border: 2px solid #fff;
    margin-bottom: 10px;
  }

  .table {
    background: transparent;
    color: #fff;

    .table-heading {
      text-transform: capitalize;
      color: #fff;
    }
  }

  & .buttons {
    margin-top: 20px;

    .heart-clicked {
      background: red;
      border: 1px solid red;
    }

    .heart-btn {
      &:hover {
        background: red;
        border: 1px solid red;
      }

      &:focus {
        background: red;
        border: 1px solid red;
      }
    }

    .desc {
      font-size: 18px;
    }
  }
}
</style>
