<template>
  <div class="header">
    <div class="container">
      <btnsNav />

      <div class="columns is-centered is-mobile is-marginless" v-if="!showTitle">
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.facebook">
          <a :href="'//'+restaurant_detail.facebook" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/facebook.svg`"
                alt
              />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.insta">
          <a :href="'//'+restaurant_detail.insta" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/instagram.svg`"
                alt
              />
            </figure>
          </a>
        </div>
        <div class="column is-narrow has-text-centered" v-if="restaurant_detail.snapchat">
          <a :href="'//'+restaurant_detail.snapchat" target="_blank">
            <figure class="image is-32x32">
              <img
                class="social-icons"
                :src="`${$store.getters.base_url}/embed/images/snapchat.svg`"
                alt
              />
            </figure>
          </a>
        </div>
      </div>

      <!-- <div class="columns is-centered is-mobile restaurant_name_row" v-if="showTitle">
        <div class="column is-narrow has-text-centered">
          <h2>{{title}}</h2>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import btnsNav from "@/components/ui/BtnsNav";

export default {
  name: "MenuMainHeaderComponent",
  components: {
    btnsNav,
  },
  computed: mapState(["restaurant_detail"]),
  methods: {
    setHeaderDetail() {
      this.title = this.restaurant_detail.name;
      this.logo = `${this.$store.getters.base_url}/${this.restaurant_detail.logo}`;
    },
  },

  watch: {
    restaurant_detail() {
      this.setHeaderDetail();
    },
  },
  data() {
    return {
      title: "",
      logo: "",
      showTitle: true,
    };
  },
  mounted() {
    this.setHeaderDetail();
    this.showTitle = this.$router.currentRoute.path != "/restaurant/feedback/";
  },
};
</script>

<style lang='scss' scoped>
.header {
  padding: 1rem 0;
  border-bottom: 1px solid #fff;
  .large-logo-detail {
    width: 150px;
    height: 150px;
  }

  & figure {
    & .social-icons {
      border: none;
      border-radius: none;
    }
  }

  & h2 {
    font-size: 40px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }
}
</style>