<template>
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-5-tablet is-5-desktop is-5-widescreen">
                <div class="box">
                    <figure class="image is-128x128">
                        <img :src="`${$store.getters.base_url}/img/icons/order_success.svg`"/>
                    </figure>
                    <div class="has-text-centered response-holder">
                        <h4>Thank You! 😊</h4>
                        <p>You have successfully placed an order</p>
                        <router-link to="/" class="button is-success">
                            <img :src="`${$store.getters.base_url}/img/icons/left.svg`"/>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "orderSucessComponent"
    };
</script>

<style lang='scss' scoped>
    .box {
        .response-holder {
            margin-top: 20px;

            & h4 {
                font-weight: bold;
                font-size: 30px;
            }

            & p {
                margin-bottom: 18px;
            }

            & img {
                width: 26px;
                height: 40px;
            }
        }
    }
</style>