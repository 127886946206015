<template>
  <div class="submenu-parent">
    <detailHeader heading="Menu" v-if="restaurant_detail.theme.id != 4"/>
    <subMenus />
    <mainFooter />
    <bottomNav
      :route_back="{
        name: 'MainMenuCategoriesComponent',
        params: {},
        display_name: restaurant_detail.localization.misc_back,
      }"
    />
  </div>
</template>

<script>
import detailHeader from "@/components/layouts/DetailHeader";
import subMenus from "@/components/menu/SubMenus";
import bottomNav from "@/components/layouts/AppNav";
import mainFooter from "@/components/layouts/Footer";
import { mapGetters } from "vuex";

export default {
  computed: mapGetters(["restaurant_detail"]),

  components: {
    detailHeader,
    subMenus,
    bottomNav,
    mainFooter,
  },
  data() {
    return {
      category_id: 0,
      subSection: [],
    };
  },
  mounted() {},
};
</script>
<style scoped>
</style>
